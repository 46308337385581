<template>
    <b-modal
        id="ModalCreateTemplateConfig"
        size="xl"
        title="Ajouter une configuration de template"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <FormTemplateConfig
            :applicationid="applicationid"
            @saved="onSaved"
            class="mb-4"
        />
    </b-modal>
</template>
<script>
import FormTemplateConfig from "@/components/model/templateconfig/FormTemplateConfig";
export default {
    components: {
        FormTemplateConfig,
    },
    props: {
        applicationid: { type: [String, Number], required: true },
    },
    methods: {
        onSaved() {
            this.$bvModal.hide("ModalCreateTemplateConfig");
            this.$emit("saved");
        },
    },
};
</script>
