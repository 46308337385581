<template>
    <b-container fluid>
        <b-form-row>
            <b-col cols="12" md="4">
                <LabelledInputText
                    label="Nom"
                    placeholder="Nom de l'application"
                    :required="true"
                    v-model="localApplication.name"
                    @validated="isNameValid = $event"
                    @input="hasChanged = true"
                />
            </b-col>
            <b-col cols="12" md="4">
                <LabelledInputText
                    label="Template"
                    placeholder="Nom du template"
                    :required="true"
                    v-model="localApplication.template"
                    :forceValidation="true"
                    @validated="isTemplateValid = $event"
                    @input="hasChanged = true"
                />
            </b-col>
            <b-col cols="12" md="4">
                <LabelledInput label="Type" :required="true">
                    <b-form-select
                        v-model="localApplication.type"
                        :options="appTypesOptions"
                        :disabled="isEditMode"
                        @input="hasChanged = true"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                -- Type de l'application --
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </LabelledInput>
            </b-col>
        </b-form-row>

        <b-form-row class="mt-2">
            <b-col cols="12">
                <LabelledInputText
                    label="Description"
                    placeholder="Description courte de l'application"
                    :required="true"
                    v-model="localApplication.description"
                    :forceValidation="true"
                    @validated="isDescriptionValid = $event"
                    @input="hasChanged = true"
                />
            </b-col>
        </b-form-row>

        <b-form-row>
            <b-col cols="12" class="text-right">
                <small class="font-italic" v-show="isEditMode">
                    <b-icon-info-circle />
                    Création le
                    {{ localApplication.createdAt | dateFromISO }} ~ Dernière
                    modification le
                    {{ localApplication.updatedAt | dateFromISO }}
                </small>
            </b-col>
        </b-form-row>

        <b-form-row class="mt-3">
            <b-col cols="12" class="justify-content-center d-flex">
                <b-button
                    class="m-auto"
                    variant="kalkin-2"
                    @click="save"
                    :disabled="!isValid || !hasChanged"
                >
                    Enregistrer
                </b-button>
            </b-col>
        </b-form-row>
    </b-container>
</template>

<script>
import Vue from "vue";
import LabelledInputText from "@/components/ui/item/LabelledInputText";
import LabelledInput from "@/components/ui/item/LabelledInput";

const defaultApplication = {
    name: undefined,
    description: undefined,
    type: undefined,
};

export default {
    components: {
        LabelledInputText,
        LabelledInput,
    },

    props: {
        application: { type: Object, required: false },
    },

    data: function() {
        return {
            hasChanged: false,
            isEditMode: this.application != null,
            isNameValid: this.application != null && this.application.name,
            isTemplateValid:
                this.application != null && this.application.template,
            isDescriptionValid:
                this.application != null && this.application.description,

            appTypes: [],

            localApplication: Vue.util.extend(
                {},
                this.application || defaultApplication,
            ),
        };
    },
    computed: {
        isValid() {
            return (
                this.isNameValid &&
                this.isTemplateValid &&
                this.isDescriptionValid &&
                this.localApplication.type
            );
        },

        appTypesOptions() {
            if (this.isEditMode) {
                let t = this.localApplication.type || "";
                return [{ text: t, value: t }];
            } else {
                return this.appTypes.map(t => {
                    return { text: t, value: t };
                });
            }
        },
    },

    methods: {
        async save() {
            if (this.isEditMode) {
                await this.$store.dispatch("application/updateApplication", {
                    ...this.localApplication,
                    applicationId: this.localApplication.id,
                });
            } else {
                await this.$store.dispatch(
                    "application/createApplication",
                    this.localApplication,
                );
            }
            this.$emit("saved");
            this.hasChanged = false;
        },
    },

    async beforeMount() {
        if (!this.isEditMode) {
            this.appTypes = await this.$store.dispatch(
                "application/getApplicationTypes",
            );
        }
    },
};
</script>

<style></style>
