<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>
                <small>
                    Application
                </small>
                {{ application.name }} ~ {{ application.template }}
            </h1>
            <div>
                <b-button
                    size="sm"
                    variant="outline-danger"
                    v-b-modal.ModalDanger
                >
                    <b-icon-trash /> Supprimer l'application
                </b-button>
            </div>
        </div>

        <FormApplication
            :key="application.id"
            v-bind:application="application"
        />

        <div class="k-flex-row my-3">
            <div class="k-flex-line" />
        </div>

        <div class="d-flex">
            <div class="k-flex-col mx-3" style="flex:1">
                <div class="k-flex-row">
                    <h3 class="k-flex-row">
                        Versions du template
                    </h3>
                    <b-button
                        size="sm"
                        variant="outline-kalkin-2"
                        v-b-modal.ModalCreateTemplateConfig
                    >
                        <b-icon-plus-circle /> Nouvelle configuration
                    </b-button>
                </div>
                <TableTemplateConfig
                    class="text-center"
                    :templateconfigs="templateConfigs"
                    @item-clicked="onTemplateConfigSelected"
                />
            </div>

            <div class="k-flex-col mx-3" style="flex:1">
                <div class="k-flex-row">
                    <h3 class="k-flex-row">
                        Builds
                    </h3>
                    <b-button
                        size="sm"
                        variant="outline-kalkin-2"
                        v-b-modal.ModalCreateApplicationBuild
                    >
                        <b-icon-plus-circle /> Nouveau build
                    </b-button>
                </div>
                <TableApplicationBuild
                    class="text-center"
                    :applicationbuilds="applicationBuilds"
                    @item-clicked="onBuildSelected"
                />
            </div>
        </div>

        <!-- Modals -->
        <ModalDanger
            message="Voulez-vous vraiment supprimer cette application?"
            @confirmed="onDeleteConfirmed"
        />
        <ModalCreateTemplateConfig
            :applicationid="applicationId"
            @saved="onTemplateConfigCreated"
        />
        <ModalCreateApplicationBuild
            :applicationid="applicationId"
            @saved="onApplicationBuildCreated"
        />
    </div>
</template>

<script>
import FormApplication from "@/components/model/application/FormApplication";
import TableTemplateConfig from "@/components/model/templateconfig/TableTemplateConfig";
import TableApplicationBuild from "@/components/model/applicationbuild/TableApplicationBuild";
import ModalDanger from "@/components/modal/ModalDanger";
import ModalCreateTemplateConfig from "@/components/model/templateconfig/ModalCreateTemplateConfig";
import ModalCreateApplicationBuild from "@/components/model/applicationbuild/ModalCreateApplicationBuild";
export default {
    components: {
        FormApplication,
        TableTemplateConfig,
        TableApplicationBuild,
        ModalDanger,
        ModalCreateTemplateConfig,
        ModalCreateApplicationBuild,
    },

    data: function() {
        return {
            applicationId: this.$route.params.applicationId,
            application: {},
            templateConfigs: [],
            applicationBuilds: [],
        };
    },

    methods: {
        async loadTemplateConfigs() {
            this.templateConfigs = await this.$store.dispatch(
                "application/getTemplateConfigs",
                {
                    applicationId: this.applicationId,
                },
            );
        },
        async loadApplicationBuilds() {
            this.applicationBuilds = await this.$store.dispatch(
                "application/getApplicationBuilds",
                {
                    applicationId: this.applicationId,
                },
            );
        },

        async onDeleteConfirmed() {
            await this.$store.dispatch("application/deleteApplication", {
                applicationId: this.applicationId,
            });
            this.$router.push({
                name: "AdminListApplications",
            });
        },
        onBuildSelected(item) {
            this.$router.push({
                name: "AdminApplicationBuild",
                params: {
                    buildId: item.id,
                },
            });
        },
        onTemplateConfigSelected(item) {
            this.$router.push({
                name: "AdminTemplateConfig",
                params: {
                    templateId: item.id,
                },
            });
        },
        async onApplicationBuildCreated() {
            await this.loadApplicationBuilds();
        },
        async onTemplateConfigCreated() {
            await this.loadTemplateConfigs();
        },
    },

    async beforeMount() {
        this.application = await this.$store.dispatch(
            "application/getApplication",
            {
                applicationId: this.applicationId,
            },
        );
        await this.loadApplicationBuilds();
        await this.loadTemplateConfigs();
    },
};
</script>

<style></style>
