<template>
    <b-modal
        id="ModalCreateApplicationBuild"
        size="xl"
        title="Ajouter un build à l'application"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <FormApplicationBuild
            :applicationid="applicationid"
            :is-read-only="false"
            @saved="onSaved"
            class="mb-4"
        />
    </b-modal>
</template>
<script>
import FormApplicationBuild from "@/components/model/applicationbuild/FormApplicationBuild";
export default {
    components: {
        FormApplicationBuild,
    },
    props: {
        applicationid: { type: [String, Number], required: true },
    },
    methods: {
        onSaved() {
            this.$bvModal.hide("ModalCreateApplicationBuild");
            this.$emit("saved");
        },
    },
};
</script>
