<template>
    <LoadingOverlay :isLoading="isLoading">
        <b-container fluid>
            <b-form-row>
                <b-col cols="12">
                    <LabelledInputText
                        label="Version"
                        placeholder="Version de la configuration"
                        :required="true"
                        v-model="localTemplateConfig.version"
                        @validated="isVersionValid = $event"
                        @input="hasChanged = true"
                    />
                </b-col>
            </b-form-row>

            <b-form-row class="mt-2">
                <b-col cols="12">
                    <LabelledInputText
                        label="Description"
                        placeholder="Description de la configuration"
                        v-model="localTemplateConfig.description"
                        @input="hasChanged = true"
                    />
                </b-col>
            </b-form-row>

            <b-form-row>
                <b-col cols="12" class="text-right">
                    <small class="font-italic" v-show="isEditMode">
                        <b-icon-info-circle />
                        Création le
                        {{ localTemplateConfig.createdAt | dateFromISO }} ~
                        Dernière modification le
                        {{ localTemplateConfig.updatedAt | dateFromISO }}
                    </small>
                </b-col>
            </b-form-row>

            <b-form-row class="mt-4 d-flex justify-content-between">
                <b-col
                    cols="12"
                    md="auto"
                    class="d-flex justify-content-center"
                >
                    <h4>Configuration</h4>
                </b-col>

                <b-col
                    cols="12"
                    md="auto"
                    class="d-flex justify-content-center align-self-center"
                >
                    <b-form-file
                        v-model="configFile"
                        accept="application/json"
                        ref="inputConfigFile"
                        style="display:none"
                    />
                    <b-button
                        size="sm"
                        variant="outline-secondary"
                        @click="selectFile"
                    >
                        Charger depuis un fichier
                    </b-button>
                </b-col>
            </b-form-row>

            <b-form-row class="mt-2">
                <b-col cols="12">
                    <b-textarea
                        rows="5"
                        max-rows="15"
                        placeholder="Pas de configuration"
                        v-model="stringifiedConfig"
                        :state="isConfigValid && null"
                        @input="hasChanged = true"
                    >
                    </b-textarea>
                </b-col>
            </b-form-row>

            <b-form-row class="mt-3">
                <b-col cols="12" class="justify-content-center d-flex">
                    <b-button
                        class="m-auto"
                        variant="success"
                        @click="save"
                        :disabled="!isValid || !hasChanged"
                        >Enregistrer</b-button
                    >
                </b-col>
            </b-form-row>
        </b-container>
    </LoadingOverlay>
</template>

<script>
import Vue from "vue";
import notifier from "@/tools/notifier";
import LoadingOverlay from "@/components/ui/LoadingOverlay";
import LabelledInputText from "@/components/ui/item/LabelledInputText";

const defaultTemplateConfig = {
    version: undefined,
    description: undefined,
    config: undefined,
};

export default {
    components: {
        LoadingOverlay,
        LabelledInputText,
    },
    props: {
        templateconfig: { type: Object, required: false },
        isReadOnly: { type: [Boolean, String], default: true },
        applicationid: { type: [String, Number], required: false },
    },
    data: function() {
        return {
            isLoading: false,
            hasChanged: false,
            isEditMode: this.templateconfig != null,
            isVersionValid:
                this.templateconfig != null && this.templateconfig.version,
            isConfigValid: true,

            localTemplateConfig: Vue.util.extend(
                {},
                this.templateconfig || defaultTemplateConfig,
            ),

            configFile: null,
        };
    },
    computed: {
        isValid() {
            return this.isVersionValid && this.isConfigValid;
        },
        stringifiedConfig: {
            get() {
                return JSON.stringify(
                    this.localTemplateConfig.config || undefined,
                    null,
                    4,
                );
            },
            set(value) {
                try {
                    this.localTemplateConfig.config = JSON.parse(value || null);
                    this.isConfigValid = true;
                } catch (error) {
                    this.isConfigValid = false;
                }
            },
        },
    },
    watch: {
        async configFile(file) {
            if (file) {
                const data = await new Response(file).text();
                try {
                    let json = JSON.parse(data);
                    this.localTemplateConfig.config = json;
                    this.hasChanged = true;
                    this.isConfigValid = true;
                } catch (error) {
                    notifier.error(
                        "Le fichier n'est pas dans un format JSON valide",
                    );
                }
            }
            this.configFile = null;
        },
    },
    methods: {
        async save() {
            this.isLoading = true;
            if (this.isEditMode) {
                await this.$store.dispatch("application/updateTemplateConfig", {
                    ...this.localTemplateConfig,
                    templateConfigId: this.localTemplateConfig.id,
                });
            } else {
                await this.$store.dispatch("application/createTemplateConfig", {
                    ...this.localTemplateConfig,
                    applicationId: this.applicationid,
                });
            }
            this.$emit("saved");
            this.isLoading = false;
            this.hasChanged = false;
        },

        selectFile() {
            this.$refs.inputConfigFile.$el.childNodes[0].click();
        },
    },
};
</script>

<style lang="scss" scoped>
textarea:read-only {
    padding: 5px;
    border-radius: 5px;
    background: #f1f1f1;
}
</style>
