<template>
    <div div class="k-flex-col">
        <b-table
            :items="applicationbuilds"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :busy="isBusy"
            @row-clicked="onRowClicked"
            tbody-tr-class="pointer"
            hover
            outlined
            stacked="md"
            show-empty
            empty-text="Aucun build"
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
import Vue from "vue";
export default {
    props: {
        applicationbuilds: { type: Array, required: false },
    },
    data: function() {
        return {
            perPage: 7,
            currentPage: 1,
            fields: [
                {
                    key: "version",
                    label: "Version",
                    sortable: true,
                },
                {
                    key: "applicationConfigCount",
                    label: "Utilisations",
                    sortable: true,
                },
                {
                    key: "createdAt",
                    label: "Date de création",
                    sortable: true,
                    formatter: Vue.filter("dateFromISO"),
                },
            ],
        };
    },
    computed: {
        isBusy() {
            return this.applicationbuilds === null;
        },
        totalRows() {
            return this.applicationbuilds ? this.applicationbuilds.length : 0;
        },
    },
    methods: {
        onRowClicked(item) {
            this.$emit("item-clicked", item);
        },
        onDelete(item) {
            this.$emit("item-delete", item);
        },
    },
};
</script>

<style scoped></style>
